import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import parse from "html-react-parser"

const FeaturedVote = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(
          filter: {
            categories: { nodes: { elemMatch: { name: { eq: "Vote" } } } }
          }
          sort: { fields: date, order: DESC }
          limit: 3
        ) {
          nodes {
            excerpt
            uri
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            postFields {
              redirectUrl
            }
            featuredImage {
              node {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(
                      webpOptions: { quality: 90 }
                      width: 716
                      formats: WEBP
                      layout: CONSTRAINED
                      aspectRatio: 1.55
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const posts = allWpPost.nodes
  return (
    <>
      <h3 className="has-text-color has-tt-darkgray-color">
        TRANSform the Vote
      </h3>
      <div className="relative mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-7xl justify-between items-strech gap-8">
        {posts.map(post => (
          <>
            <article
              key={post.uri}
              itemScope
              itemType="http://schema.org/Article"
              className="relative flex flex-col justify-end rounded-lg"
            >
              <div className="absolute w-full p-4 bg-gradient-to-b from-transparent to-ttBlack80 rounded-b-lg">
                <h5
                  className="font-bold !text-white text-lg md:text-2xl lg:text-xl xl:text-2xl mb-2"
                  itemProp="headline"
                >
                  {parse(post.title)}
                </h5>
              </div>
              <GatsbyImage
                image={getImage(post.featuredImage?.node.localFile)}
                alt={post.featuredImage?.node?.alt}
                className="relative -z-10 mb-0 drop-shadow-harsh border-2 border-black rounded-lg"
              />
              <a
                href={post.postFields.redirectUrl}
                className="absolute h-full w-full z-10"
              ></a>
            </article>
          </>
        ))}
      </div>
    </>
  )
}

export default FeaturedVote
