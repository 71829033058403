import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedNews from "../components/featuredNews"
import TransFormTheVote from "../components/transformTheVote"
import JotformEmbed from "react-jotform-embed"

export default function Index(props) {
  const { post } = props.data
  const featuredImage = {
    fluid: getImage(post.featuredImage?.node.localFile),
    alt: post.featuredImage?.node?.altText || post.title,
  }

  return (
    <Layout>
      <div
        className={
          post.pageACF.colorScheme + " h-[200px] featured-image-wrapper home"
        }
      >
        {featuredImage?.fluid && (
          <>
            <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full overflow-x-hidden">
              <GatsbyImage
                image={featuredImage.fluid}
                alt={featuredImage.alt}
                className="!absolute -z-20 !w-screen"
              />
              <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>
              <div
                className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
                style={{
                  backgroundImage:
                    "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
                }}
              ></div>
            </div>
          </>
        )}
      </div>
      <div className={post.pageACF.colorScheme + " " + post.slug + " page"}>
        <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="home-title w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
            >
              Get empowered.
              <br />
              Get educated.
              <br />
              Get employed.
            </h1>
            <div className="home-form w-full">
              <p className="text-left text-lg max-w-[500px] lg:max-w-[650px] mx-0">
                Are you a transgender individual or ally? Join TransTech for
                free and get the practical, career-ready skills you need to
                survive and thrive in this economy.
              </p>
              <JotformEmbed src="https://form.jotform.com/230453640119045" />
            </div>
          </div>
          {/*<div class="home-title w-full entry-content">
            <h1
              itemprop="headline"
              class="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
            >
              Beyond the Code
            </h1>
            <div class="home-form w-full">
              <p class="text-left text-lg max-w-[500px] lg:max-w-[650px] !mx-0">
                Join us for the <b>TransTech Summit,</b> our FREE annual online
                event created by members of our community, for the community.
                Grow in your existing career, network with other LGBTQIA people,
                learn new skills, and much more...
              </p>
              <div class="is-content-justification-start is-layout-flex wp-container-35 wp-block-buttons">
                <div class="wp-block-button">
                  <a
                    class="wp-block-button__link has-orange-to-purple-gradient-background has-background wp-element-button"
                    href="https://transtechsummit.com/"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <p class="!mx-0 !text-base">
                Thursday, March 28 - Sunday, March 31, 2024
              </p>
            </div>
          </div>*/}

          <div class="mt-4 p-4 bg-black text-white rounded-lg text-center">
            🏳️‍🌈 🏳️{" "}
            <a
              class="underline hover:text-yellow-400"
              href="https://shop.transtechsocial.com/?mc_cid=1730be6f2d&mc_eid=b3c0658b9d"
              target="_blank"
            >
              Get your TransTech&nbsp;Swag&nbsp;Here
            </a>{" "}
            🔥
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between gap-8 mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="entry-content w-full"
            >
              <TransFormTheVote />
              <FeaturedNews />
              {!!post.content && parse(post.content)}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = props => {
  const { post } = props.data
  return (
    <Seo
      title={parse(post.title) + " | TransTech Social Enterprises"}
      description={post.smartcrawlSeo?.metaDesc}
      ogTitle={post.opengraphTitle}
      ogDescription={post.smartcrawlSeo?.opengraphDescription}
      twTitle={post.smartcrawlSeo?.twitterTitle}
      twDescription={post.smartcrawlSeo?.twitterDescription}
      featuredImage={post.featuredImage?.node?.mediaItemUrl}
      ogImage={post.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
      twImage={post.smartcrawlSeo?.twitterImage?.mediaItemUrl}
    />
  )
}

export const query = graphql`
  {
    post: wpPage(isFrontPage: { eq: true }) {
      id
      content
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      jotformUrl {
        jotformUrl
      }
      pageACF {
        colorScheme
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 1920
                formats: WEBP
                layout: FIXED
                aspectRatio: 1.778
              )
            }
          }
        }
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }
  }
`
